@import 'variables';

#popup {
  width: 250px;
  position: absolute;
  z-index: 10000000000000000000;
  border: 1px solid #f6f6f6;
  border-radius: 3px;

  &.top .popup-box {
    &:after, &:before {
      top: 100%;
      left: 20%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(246, 246, 246, 0);
      border-top-color: $primary-color;
      border-width: 10px;
      margin-left: -10px;
    }
    &:before {
      border-color: rgba(30, 46, 90, 0);
      border-top-color: $primary-color;
      border-width: 11px;
      margin-left: -11px;
    }
  }

  &.bottom .popup-box {
    &:after, &:before {
      bottom: 100%;
      left: 26%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(246, 246, 246, 0);
      border-bottom-color: #f6f6f6;
      border-width: 10px;
      margin-left: -10px;
    }
    &:before {
      border-color: rgba(0, 0, 0, 0);
      border-bottom-color: $primary-color;
      border-width: 11px;
      margin-left: -11px;
    }
  }

  .popup-box {
    width: 100%;
    display: inline-block;
    position: relative;
    background: #f6f6f6;
    border: 1px solid $primary-color;
    border-radius: 3px;

    .content {
      position: relative;
      padding: 15px;

      img {
        position: absolute;
        width: 20px;
        top: 5px;
        right: 5px;
        opacity: 0.8;

        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }

      h4.popup-header {
        color: $primary-color;
        font-size: 13px !important;
        line-height: 1.56 !important;
        font-weight: 700 !important;
        margin: 0 0 5px 0 !important;
        margin-bottom: 5px !important;
        display: inline-block !important;
      }

      p.popup-text {
        font-size: 11px !important;
        line-height: 1.9 !important;
      }
    }

    button {
      border: 0;
      background-color: #ddd;
      display: inline;
      width: 100%;
      font-size: 14px;
      padding: 6px 0;
      font-weight: 600;
      background: $primary-color;
      color: white;
      transition: background 0.2s ease-in-out;
      border-radius: 0 0 1px 0;

      &:hover {
        background: darken($primary-color, 5%);
        color: white;
      }

      &:focus {
        outline: none;
      }

      &.close-popup {
        background: #ececec;
        color: $primary-color;
        border-radius: 0 0 0 2px;
      }
    }
  }
}
