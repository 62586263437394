@import 'variables';

#library-idea-wrapper {
  // height: 100%;
  display: flex;
  flex-flow: column;

  #library-idea-toolbar {
    width: 100%;
    flex: 0 1 auto;
    background: #f6f6f6;

    #library-idea-toolbar-container {
      display: grid;
      grid-template-columns: 70% 30%;
      grid-template-rows: auto;
      padding: 6px 0;

      #library-toolbar {
        grid-column-start: 1;
        grid-column-end: 2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        input, select, h4 {
          display: inline;
        }

        h4 {
          font-size: 14px;
          font-weight: 700;
          margin: 0;
        }

        select {
          margin-left: auto;
          font-size: 12px;
          width: 200px;
        }

        input {
          margin: 0 15px;
          font-size: 12px;
          width: 200px;
        }
      }

      #idea-box-toolbar {
        grid-column-start: 2;
        grid-column-end: 3;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        h4 {
          font-size: 14px;
          font-weight: 700;
          margin: 0 0 8px 10px;
        }

        button {
          margin: 0;
        }
      }
    }
  }

  #library-idea {
    width: 100%;
    background: $primary-color;
    flex: 1 1 auto;
    position: relative;
    display: flex;

    .container {
      z-index: 0;
    }

    #library-idea-container {
      display: grid;
      grid-template-columns: 70% 30%;
      grid-template-rows: auto;
      height: 100%;

      #library {
        grid-column-start: 1;
        grid-column-end: 2;
        height: 100%;
        border-right: 5px solid #fff;
        position: relative;
        z-index: 1000;
        background: $primary-color;

        #library-cont {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          overflow-y: scroll;
          padding: 25px 0 0 0;

          .library-category {
            display: grid;
            grid-template-columns: 100px auto;
            grid-template-rows: auto;

            margin-bottom: 30px;
            color: white;

            .category-column {
              grid-column-start: 1;
              grid-column-end: 2;

              h2 {
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                opacity: .8;
              }
            }

            .subcategory-column {
              grid-column-start: 2;
              grid-column-end: 3;
              padding: 0 25px 0 15px;

              .library-subcategory {
                margin-bottom: 20px;

                h4 {
                  margin: 1px 0 15px;
                  padding: 0 0 5px;
                  font-size: 12px;
                  font-weight: bold;
                  opacity: .8;
                  border-bottom: 1px solid rgba(255, 255, 255, .2);
                }

                .motifs-container {
                  display: flex;
                  flex-wrap: wrap;

                  .no-results {
                    font-style: italic;
                  }

                  .motif {
                    height: 34px;
                    img {
                      transition: transform 0.2s ease-in-out;
                      &:hover {
                        transform: scale(1.3);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      #idea-box {
        grid-column-start: 2;
        grid-column-end: 3;
        position: relative;
        height: 100%;

        #idea-cont {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          overflow-y: scroll;

          #idea-content {
            display: flex;
            align-content: flex-start;
            flex-wrap: wrap;
            overflow-y: scroll;
            padding: 25px;

            .motif {
              height: 34px;
            }
          }
        }
      }
    }
  }
}
