@import "variables";

.preview-motif {
  margin: 0;
  height: 100%;
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 100;

  img {
    height: 100%;
    z-index: 102;
  }

  .svg {
    display: flex;
    align-items: center;
    svg {
      height: 100%;
      z-index: 1000;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.premium {
    min-width: 63px;
  }

  .premium-tag {
    position: absolute;
    bottom: -20px;
    background: #666;
    font-size: 10px;
    color: white;
    z-index: 101;
    text-align: center;
    padding: 0px 10px;
    border-radius: 2px;
    opacity: 0.6;
  }
}

@media (max-width: 1200px) {
  .preview-motif {
    min-width: 40px;
  }
}
