@import 'variables';

#submit-belt {
  width: 100%;
  min-height: 190px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  position: relative;
  background: #f6f6f6;

  h4 {
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 10px;

    span {
      font-size: 10px;
      font-weight: 600;
      margin-left: 5px;
    }
  }

  #belt-mock {
    width: 100%;
    background-color: $primary-color;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 28px;
    transition: background-color .2s ease-in-out;
    box-shadow: 2px 2px 20px #bbb;

    &.isActive {
      background-color: lighten($primary-color, 3%);
    }

    .motif {
      margin: 0;
    }
  }
}
