@import "variables";

.motif {
  margin: 0 30px 25px 0;
  height: 100%;
  color: white;
  position: relative;
  display: flex;
  justify-content: center;

  img {
    height: 100%;
    z-index: 1000;

    &:hover {
      cursor: pointer;
    }
  }
  .svg {
    display: flex;
    align-items: center;
    
    svg {     
      height: 100%;
      z-index: 1000;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.premium {
    min-width: 63px;
  }

  .motif-personalization {
    display: none;
  }

  .motif-personalization-edit {
    display: none;
  }

  &.belt {
    margin: 0;
    .premium-tag {
      bottom: -20px;
    }
    .motif-info-container {
      top: 100%;

      .motif-info {
        margin-top: 0px;
        border: 1px solid #ccc;
      }
    }
    .motif-personalization {
      display: inline-block;
      position: absolute;
      left: 0;
      bottom: -44px;
      width: 100%;
      color: black;
      line-height: 1;
      font-size: 13px;
      text-align: center;

      p {
        display: inline;
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }
      }

      .status {
        margin-left: 10px;
      }
    }

    .motif-personalization-edit {
      display: inline-block;
    }
  }

  .premium-tag {
    position: absolute;
    bottom: -12px;
    background: #666;
    font-size: 10px;
    color: white;
    text-align: center;
    padding: 0px 10px;
    border-radius: 2px;
    opacity: 0.6;
  }

  .motif-tools {
    position: absolute;
    top: -34px;
    right: -20px;
    background: transparent;
    height: 38px;
    width: 63px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    .tool {
      position: relative;
      background: #f6f6f6;
      color: white;
      border: 1px solid #ccc;
      border-radius: 50% 50% 50% 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 30px;

      p {
        font-size: 26px;
        margin-bottom: 2px;
        color: #666;
        line-height: 1;
        font-weight: 600;
        transition: transform 0.2s ease-in-out;
      }

      &:hover {
        cursor: pointer;
        p {
          color: red;
        }
      }
    }
  }

  .motif-info-container {
    position: absolute;
    top: 60%;

    .motif-info {
      margin-top: 30px;
      background: white;
      color: #222;
      white-space: nowrap;
      padding: 3px 10px;
      border-radius: 3px;
      transition: opacity 0.5s ease-in-out;
      text-align: center;
      z-index: 103;

      span.why {
        color: $primary-color;
        text-decoration: underline;

        &:hover {
          color: darken($primary-color, 5%);
          cursor: pointer;
        }
      }

      .upcharge {
        color: green;
      }
    }
  }

  &.belt {
    transition: transform 0.2s ease-in-out;
    &:hover {
      transform: scale(1.00001);
    }
  }
}

@media (max-width: 1200px) {
  .motif.belt {
    min-width: 40px;

    .motif-tools {
      min-width: 40px;
    }
  }
}
