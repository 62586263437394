@import 'variables';

#notification {
  position: absolute;
  bottom: -48px;
  left: 50%;
  transform: translate(-50%, 0);
  border: 2px solid $primary-color;
  border-radius: 3px;
  height: 38px;
  z-index: 100;
  
  &.error {
    border-color: red;
    div p {
      color: red;
    }
  }

  &.premium {
    border-color: green;
    div p {
      color: green;
    }
  }
  
  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    p {
      color: $primary-color;
      font-size: 11px;
      font-weight: 600;
    }
  }

}