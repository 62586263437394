@import 'variables';

#submit-form {
  padding: 30px 0 50px;
  background: $header-background;
  border-top: 2px solid $hr;
  display: flex;
  justify-content: center;
  align-items: center;

  button#pay-button,
  button#draft-button {
    width: 100%;
    text-transform: none;
    text-align: left;
    padding: 12px;

    p.main {
      font-size: 13px;
      margin-bottom: 2px;
      font-weight: 700;
      span {
        position: absolute;
        right: 30px;
      }
    }
    p.sub {
      font-size: 12px;
      font-weight: normal;
      line-height: 1.7;
    }
  }

  button#pay-button {
    margin-bottom: 30px;
  }

  button#draft-button {
    background-color: $header-background;
    color: $button-background;
    border: 1px solid $button-background;

    &:hover {
      background-color: darken($header-background, 10%);
    }
  }

  .col-5 {
    display: flex;
    align-items: center;
  }

  #form {
    width: 100%;

    .subrow {
      display: flex;
      justify-content: space-between;
    }

    h4 {
      font-size: 14px;
      font-weight: 700;
      margin: 0 0 10px;
    }

    hr {
      margin: 10px 0 15px;
    }

    input {
      margin-bottom: 15px;

      &.half {
        width: 49%;
      }

      &.city {
        width: 66%;
      }

      &.state {
        width: 12%;
      }

      &.zip {
        width: 20%;
      }
    }

    textarea {
      margin-bottom: 15px;
      font-size: 12px;
    }

    .error {
      margin: 15px 0;
      color: red;
      font-weight: bold;
    }

    input,
    text-area {
      &.error-field {
        border: 2px solid red;
      }
    }
  }

  #form-info {
    padding: 0 25px;
    overflow-y: scroll;
    height: 380px;

    h2 {
      font-size: 16px;
      font-weight: 800;
      margin-bottom: 15px;
      color: $font;
    }

    h4 {
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 15px;
      color: $font;
    }

    p {
      font-size: 13px;
      margin-bottom: 25px;
      line-height: 1.75;
      font-weight: 500;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      font-size: 12px;
      font-style: italic;
      color: $primary-color;
      text-decoration: underline;
    }
  }

  .scroll-down {
    padding: 0 25px;
    font-size: 12px;
    color: #aaa;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-style: italic;
  }
}

.subInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;

  .field {
    display: flex;
    justify-content: flex-start;
    padding: 5px;
  }
  .fieldName {
    font-size: 12px;
    width: 60px;
    display: flex;
    justify-content: flex-start; 
    margin-right: 10px;   
  }
  .fieldValue {
    font-size: 13px;
    display: flex;
    justify-content: flex-start;
  }
}
