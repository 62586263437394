#submit {
  display: flex;
  flex-flow: column;
  height: 100vh;

  #header {
    flex: 0 1 auto;
  }

  #toolbar {
    flex: 0 1 auto;
  }

  #belt {
    flex: 0 1 auto;
  }

  #submit-form, #submit-complete {
    flex: 1 1 auto;
  }
}
