@import 'variables';
$lg: 4px;
$md: 8px;
$sm: 14px;

#belt,
#submit-belt {
  #belt-mock {
    &.belt-size-28 {
      height: 68px;
    }

    &.belt-size-29 {
      height: 66px;
    }

    &.belt-size-30 {
      height: 64px;
    }

    &.belt-size-31 {
      height: 62px;
    }

    &.belt-size-32 {
      height: 60px;
    }

    &.belt-size-33 {
      height: 58px;
    }

    &.belt-size-34 {
      height: 56px;
    }

    &.belt-size-35 {
      height: 54px;
    }

    &.belt-size-36 {
      height: 52px;
    }

    &.belt-size-37 {
      height: 50px;
    }

    &.belt-size-38 {
      height: 48px;
    }

    &.belt-size-39 {
      height: 47px;
    }

    &.belt-size-40 {
      height: 46px;
    }

    &.belt-size-41 {
      height: 45px;
    }

    &.belt-size-42 {
      height: 44px;
    }

    &.belt-size-43 {
      height: 43px;
    }

    &.belt-size-44 {
      height: 42px;
    }

    &.belt-size-45 {
      height: 41px;
    }

    &.belt-size-46 {
      height: 40px;
    }

    &.belt-size-47 {
      height: 39px;
    }

    &.belt-size-48 {
      height: 38px;
    }

    &.belt-size-49 {
      height: 38px;
    }

    &.belt-size-50 {
      height: 37px;
    }

    &.belt-size-51 {
      height: 37px;
    }

    &.belt-size-52 {
      height: 37px;
    }
  }
}

// Larger container widths
@media (max-width: 1400px) {
  #belt,
  #submit-belt {
    #belt-mock {
      &.belt-size-28 {
        height: 68px - $lg;
      }

      &.belt-size-29 {
        height: 66px - $lg;
      }

      &.belt-size-30 {
        height: 64px - $lg;
      }

      &.belt-size-31 {
        height: 62px - $lg;
      }

      &.belt-size-32 {
        height: 60px - $lg;
      }

      &.belt-size-33 {
        height: 58px - $lg;
      }

      &.belt-size-34 {
        height: 56px - $lg;
      }

      &.belt-size-35 {
        height: 54px - $lg;
      }

      &.belt-size-36 {
        height: 52px - $lg;
      }

      &.belt-size-37 {
        height: 50px - $lg;
      }

      &.belt-size-38 {
        height: 48px - $lg;
      }

      &.belt-size-39 {
        height: 47px - $lg;
      }

      &.belt-size-40 {
        height: 46px - $lg;
      }

      &.belt-size-41 {
        height: 45px - $lg;
      }

      &.belt-size-42 {
        height: 44px - $lg;
      }

      &.belt-size-43 {
        height: 43px - $lg;
      }

      &.belt-size-44 {
        height: 42px - $lg;
      }

      &.belt-size-45 {
        height: 41px - $lg;
      }

      &.belt-size-46 {
        height: 40px - $lg;
      }

      &.belt-size-47 {
        height: 39px - $lg;
      }

      &.belt-size-48 {
        height: 38px - $lg;
      }
    }
  }
}

@media (max-width: 1300px) {
  #belt,
  #submit-belt {
    #belt-mock {
      &.belt-size-28 {
        height: 68px - $md;
      }

      &.belt-size-29 {
        height: 66px - $md;
      }

      &.belt-size-30 {
        height: 64px - $md;
      }

      &.belt-size-31 {
        height: 62px - $md;
      }

      &.belt-size-32 {
        height: 60px - $md;
      }

      &.belt-size-33 {
        height: 58px - $md;
      }

      &.belt-size-34 {
        height: 56px - $md;
      }

      &.belt-size-35 {
        height: 54px - $md;
      }

      &.belt-size-36 {
        height: 52px - $md;
      }

      &.belt-size-37 {
        height: 50px - $md;
      }

      &.belt-size-38 {
        height: 48px - $md;
      }

      &.belt-size-39 {
        height: 47px - $md;
      }

      &.belt-size-40 {
        height: 46px - $md;
      }

      &.belt-size-41 {
        height: 45px - $md;
      }

      &.belt-size-42 {
        height: 44px - $md;
      }

      &.belt-size-43 {
        height: 43px - $md;
      }

      &.belt-size-44 {
        height: 42px - $md;
      }

      &.belt-size-45 {
        height: 41px - $md;
      }

      &.belt-size-46 {
        height: 40px - $md;
      }

      &.belt-size-47 {
        height: 39px - $md;
      }

      &.belt-size-48 {
        height: 38px - $md;
      }
    }
  }
}

@media (max-width: 1200px) {
  #belt,
  #submit-belt {
    #belt-mock {
      &.belt-size-28 {
        height: 68px - $sm;
      }

      &.belt-size-29 {
        height: 66px - $sm;
      }

      &.belt-size-30 {
        height: 64px - $sm;
      }

      &.belt-size-31 {
        height: 62px - $sm;
      }

      &.belt-size-32 {
        height: 60px - $sm;
      }

      &.belt-size-33 {
        height: 58px - $sm;
      }

      &.belt-size-34 {
        height: 56px - $sm;
      }

      &.belt-size-35 {
        height: 54px - $sm;
      }

      &.belt-size-36 {
        height: 52px - $sm;
      }

      &.belt-size-37 {
        height: 50px - $sm;
      }

      &.belt-size-38 {
        height: 48px - $sm;
      }

      &.belt-size-39 {
        height: 47px - $sm;
      }

      &.belt-size-40 {
        height: 46px - $sm;
      }

      &.belt-size-41 {
        height: 45px - $sm;
      }

      &.belt-size-42 {
        height: 44px - $sm;
      }

      &.belt-size-43 {
        height: 43px - $sm;
      }

      &.belt-size-44 {
        height: 42px - $sm;
      }

      &.belt-size-45 {
        height: 41px - $sm;
      }

      &.belt-size-46 {
        height: 40px - $sm;
      }

      &.belt-size-47 {
        height: 39px - $sm;
      }

      &.belt-size-48 {
        height: 38px - $sm;
      }
    }
  }
}
