@import 'variables';

#generator {
  display: flex;
  flex-flow: column;
  height: 100vh;

  #header {
    flex: 0 1 auto;
  }

  #toolbar {
    flex: 0 1 auto;
  }

  #belt {
    flex: 0 1 auto;
  }

  #library-idea-wrapper {
    flex: 1 1 auto;
  }
}

#generator-mobile {
  display: flex;
  flex-flow: column;
  height: 100vh;
  background: #f6f6f6;
}

#mbnycu {
  display: none;
}

// Larger container widths
@media (max-width: 1300px) {

}

@media (min-width: 1300px) {
  .container {
    max-width: 1235px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1330px;
  }
}

@media (max-width: 767px) {
  #generator,
  #submit {
    display: none;
  }
  
  #mbnycu {
    background: #f9f9f9;
    width: 100vw;
    height: 100vh;
    padding: 15px;
    display: flex;
    justify-content: center;

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }
}
