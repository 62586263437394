@import "variables";

.personalized-data-info {
  position: absolute;
  top: 80px;
  background: #f6f6f6;
  opacity: 1;
  padding: 10px;
  border-radius: 3px;
  min-width: 110px;
  max-width: 190px;
  text-align: center;
  color: black;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #ccc;
  opacity: 0.97;
  font-weight: 600;

  p {
    font-family: Courier New;
    font-size: 14px;
    white-space: nowrap;
  }

  span {
    font-family: "Open Sans", sans-serif;
  }

  div {
    margin-bottom: 15px;

    .block {
      width: 20px;
      height: 10px;
      display: inline-block;
      vertical-align: middle;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.motif-personalization-edit {
  display: block;
  position: absolute;
  top: 115px;
  color: black;
  line-height: 1;
  font-size: 13px;
  background: #f6f6f6;
  border-radius: 3px;
  border: 1px solid #f6f6f6;
  width: 300px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .personalization-wrapper {
    border: 1px solid $primary-color;
    border-radius: 3px;

    p {
      display: inline;
      line-height: 1.7;

      &:hover {
        cursor: pointer;
      }
    }

    .status {
      margin-left: 10px;
    }

    .content {
      padding: 20px;

      .suffix {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 5px;
        p {
          background: #ddd;
          border-radius: 2px;
          font-size: 12px;
          padding: 10px 11px;
          margin: 8px 3px 0;
          line-height: 1;

          &:hover {
            background: darken(#ddd, 5%);
          }
        }
      }

      .initialsButtons {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 5px;
        margin-bottom: 5px;

        div {
          width: 40px;
          height: 34px;
          background: #ffffff;
          border: 1px solid $primary-color;
          font-size: 18px;
          padding: 5px;
          margin: 10px 4px 0;
          line-height: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background: darken(#ddd, 5%);
          }
          img {
            height: 20px;
          }
        }
      }

      .character-rules {
        margin-top: 10px;
        p {
          font-size: 11px;
          color: #999;
        }
      }

      p {
        display: inline-block;
        line-height: 1.7;
        margin: 0 5px;

        &:hover {
          cursor: pointer;
        }
      }

      .status {
        margin-left: 10px;
      }

      .input-container {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-height: 300px;
        // overflow-y: scroll;

        &.scroll {
          overflow-y: scroll;
        }

        .suffix-child {
          margin-left: 3px;

          p {
            background: #ddd;
            border-radius: 2px;
            font-size: 12px;
            padding: 8px 10px;
            margin: 0px 3px 12px;
            line-height: 1;

            &:hover {
              background: darken(#ddd, 5%);
            }
          }
        }

        .suffix {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          // margin-top: 5px;
          p {
            background: #ddd;
            border-radius: 2px;
            font-size: 12px;
            padding: 8px 10px 8px;
            margin: 3px 3px 0;
            line-height: 1;
  
            &:hover {
              background: darken(#ddd, 5%);
            }
          }
        }

        &.no-flex {
          display: inline-block;
          // justify-content: none;
          // align-items: none;
        }

        .edit-flex {
          display: flex;
          align-items: center;
          justify-content: flex-start;          
        }

        p {
          line-height: 1;
          font-size: 22px;
          margin: 0 5px;
          color: #999;
          height: 26px;
        }

        h5 {
          font-size: 13px;
          font-weight: 700;
          margin: 0 0 0 5px;
          text-decoration: underline;
        }

        input {
          width: 40px;
          padding: 5px;
          font-size: 15px;
          margin: 4px;
          text-align: center;

          &.date {
            width: 50px;
          }
        }

        select {
          width: 100%;
          margin: 5px;
          background: white;

          &.color {
            border: 4px solid #ccc;
            outline: none;

            &:focus {
              outline: none;
            }
          }
        }

        .chooseLetters{
          font-size: 11px;
          font-weight: 600;
          display: block;
          margin: 10px 2px;
          color: #999;
        }

        .radio-group {
          margin: 0 5px;

          div {
            display: inline;
          }

          select {
            margin: 5px 0 0;
            font-size: 11px;
          }

          p {
            font-size: 11px;
            font-weight: 600;
            display: block;
            margin: 10px 0 5px;
          }

          label {
            margin-bottom: 0px;

            &:hover {
              cursor: pointer;
            }
          }

          input[type="radio"] {
            visibility: hidden;
            position: absolute;
          }
          input[type="radio"] + label:before {
            height: 25px;
            width: 25px;
            margin-right: 8px;
            content: " ";
            display: inline-block;
            vertical-align: baseline;            
          }

          input[type="radio"]:checked + label:before {
            border: 2px solid white;
            box-shadow: 0 0 3px #666;
            transform: scale(1.3);
          }

          input[type="radio"].light-skin + label:before {
            background-color: #f7dec0;
          }
          input[type="radio"].medium-skin + label:before {
            background-color: #c79444;
          }
          input[type="radio"].dark-skin + label:before {
            background-color: #82571e;
          }
          input[type="radio"].brown2 + label:before {
            background-color: #82571e;
          }
          input[type="radio"].brown + label:before {
            background-color: #996520;
          }
          input[type="radio"].dark-brown + label:before {
            background-color: #563914;
          }
          input[type="radio"].blonde + label:before {
            background-color: #fccb20;
          }
          input[type="radio"].black + label:before {
            background-color: #000;
          }
          input[type="radio"].red + label:before {
            background-color: #cc6214;
          }
          input[type="radio"].blue + label:before {
            background-color: #5c95d4;
          }
          input[type="radio"].pink + label:before {
            background-color: #f57493;
          }
          input[type="radio"].green + label:before {
            background-color: #239a4f;
          }
          input[type="radio"].clothing-red + label:before {
            background-color: #bc0000;
          }
          input[type="radio"].purple + label:before {
            background-color: #9f89d7;
          }
          input[type="radio"].orange + label:before {
            background-color: #f8750c;
          }
          input[type="radio"].initials-pink + label:before {
            background-color: #f994ae;
          }
          input[type="radio"].initials-blue + label:before {
            background-color: #8cbddf;
          }
          input[type="radio"].initials-light-blue + label:before {
            background-color: #8cbddf;
          }
          input[type="radio"].initials-mint + label:before {
            background-color: #89dc97;
          }
          input[type="radio"].initials-white + label:before {
            background-color: #ffffff;          
            box-shadow: 0 0 1px #000;         
          }
          input[type="radio"].initials-cornflower + label:before {
            background-color: #5c95d4;
          }
          input[type="radio"].initials-red + label:before {
            background-color: #bc0000;
          }
          input[type="radio"].initials-melon + label:before {
            background-color: #f67668;
          }
          input[type="radio"].initials-kelly + label:before {
            background-color: #239a4f;
          }
          input[type="radio"].initials-moss + label:before {
            background-color: #85bb64;
          }
          input[type="radio"].initials-orange + label:before {
            background-color: #f8750c;
          }
          input[type="radio"].initials-purple + label:before {
            background-color: #7859c6;
          }         
          input[type="radio"].initials-yellow + label:before {
            background-color: #fccb20;
          }
          input[type="radio"].initials-gold + label:before {
            background-color: #e0be61;
          }
          input[type="radio"].initials-lightGarnet + label:before {
            background-color: #980a0a;
          }
        }
      }

      .edit-error {
        margin-top: 10px;
        color: red;
      }
    }

    button {
      border: 0;
      background-color: #ddd;
      display: inline;
      width: 100%;
      font-size: 14px;
      padding: 10px 0;
      font-weight: 600;
      background: $primary-color;
      color: white;
      transition: background 0.2s ease-in-out;
      border-radius: 0 0 1px 0;

      &:hover {
        background: darken($primary-color, 5%);
        color: white;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

[datatip] {
  position: relative;
}
[datatip]:before {
  content: "";
  display: none;
  content: "";
  position: absolute;
  top: 0px;
  left: 30px;
  z-index: 100000000000;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0;
}
[datatip]:after {
  display: none;
  content: attr(datatip);
  position: absolute;
  top: -32px;
  left: 22px;
  padding: 5px 8px;
  background: #f6f6f6;
  color: black;
  z-index: 100000000000;
  font-size: 0.75em;
  height: 26px;
  line-height: 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  white-space: nowrap;
  word-wrap: normal;
  border: 1px solid #ccc;
}
[datatip]:hover:before,
[datatip]:hover:after {
  display: block;
}
