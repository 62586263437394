@import 'variables';

#toolbar {
  position: relative;
  width: 100%;
  background: $header-background;
  border-bottom: 2px solid $hr;

  #toolbar-container {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .toolbar-left {
      display: flex;
      justify-content: center;
      align-items: center;

      img.logo {
        height: 40px;
        margin-left: -8px;
      }

      a {
        font-size: 12px;
        color: $primary-color;
        font-weight: 600;
        margin-right: 15px;
        margin-left: 15px;
      }
    }

    .toolbar-center {
      display: flex;
      justify-content: center;
      align-items: center;
      .toolbar-item {
        margin-right: 35px;
        display: inline-block;
      }
    }

    .toolbar-right {
      display: flex;
      justify-content: center;
      align-items: center;

      .toolbar-item {
        margin-right: 35px;
        display: inline-block;
      }

      .toolbar-item-size {       
        display: inline-block;
      }

      .share-container {
        position: relative;
        display: flex;
        justify-content: center;

        #share-popup {
          width: 300px;
          margin-left: 15px;
          background: #f6f6f6;
          border: 1px solid #ccc;
          border-radius: 3px;
          padding: 10px 15px 15px;
          position: absolute;
          top: 140%;
          z-index: 1000000000;

          p.copy-instructions {
            margin-bottom: 5px;
            font-size: 11px;
            padding: 5px 0;
          }

          p.copy-link {
            background: white;
            padding: 5px 7px;
            border-radius: 3px;
            font-size: 8px;
            font-weight: 600;
            text-align: center;
          }
        }
      }
    }
  }
}

#toolbar-submit-container {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;

  .toolbar-section {
    display: flex;
    justify-content: center;
    align-items: center;

    img.logo {
      height: 40px;
      margin-left: -8px;
    }

    a {
      font-size: 12px;
      color: $primary-color;
      font-weight: 600;
      margin-right: 20px;
    }

    div {
      display: inline-block;
      margin: 0 45px 0 0;
      color: $primary-color;
    }


  }
}

.toolbar-item-content {
  display: block;
  font-weight: 700;
  font-size: 22px;
  color: $font;
  line-height: 1;
  margin-bottom: 4px;
}

.toolbar-item-label {
  display: block;
  font-size: 10px;
  font-weight: 500;
  color: $font;
  line-height: 1;
}

.small {
  margin-left: 5px;
  font-weight: 700;
  font-size: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $primary-color;
}

input:focus + .slider {
  box-shadow: 0 0 1px $primary-color;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

@media (max-width: 1300px) {
  #toolbar #toolbar-container .toolbar-left img.logo {
    height: 28px;
  }
}

@media (max-width: 1025px) {
  #toolbar {
    #toolbar-container {
      padding: 15px 0;

      .toolbar-left {
        img.logo {
          height: 22px;
        }

        a {
          font-size: 11px;
        }
      }

      .toolbar-right {
        flex-wrap: wrap;

        button {
          font-size: 10px;
          padding: 5px 8px;
          margin-top: 10px;
        }
        .toolbar-item {
          margin-right: 35px;
          display: inline-block;
        }

        .share-container {
          position: relative;
          display: flex;
          justify-content: center;

          #share-popup {
            width: 300px;
            margin-left: 15px;
            background: #f6f6f6;
            border: 1px solid #ccc;
            border-radius: 3px;
            padding: 10px 15px 15px;
            position: absolute;
            top: 140%;
            z-index: 1000000000;

            p.copy-instructions {
              margin-bottom: 5px;
              font-size: 11px;
              padding: 5px 0;
            }

            p.copy-link {
              background: white;
              padding: 5px 7px;
              border-radius: 3px;
              font-size: 8px;
              font-weight: 600;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
