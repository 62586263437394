@import "variables";

#modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  z-index: 100000;

  &:hover {
    cursor: pointer;
  }
}

.modal-wrapper {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  z-index: 1000000;
  width: 60%;
  min-height: 300px;
  background: #f6f6f6;
  border-radius: 4px;
  box-shadow: 0 0 20px #aaa;
  padding: 35px;
  border: 2px solid $primary-color;

  .underline {
    text-decoration: underline;
  }

  &#premium-modal {
    width: 500px;
    display: flex;
    align-items: center;
    min-height: 100px;

    .close-buttons button.btn.grey {
      width: 100% !important;
    }
  }

  &#size-modal {
    max-width: 900px;
    max-height: 100vh;
    overflow-y: scroll;

    .size-modal-header {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }

    .size-modal-text-container {
      display: flex;
      align-items: center;
    }

    img.img-left {
      width: 300px;
      margin-left: 5px 0 15px;
      padding-right: 20px;
      display: inline;
    }

    img.img-right {
      width: 525px;
      margin: 5px 0 10px;
    }
  }

  &#person-info-modal {
    max-width: 550px;
    max-height: 90vh;
    overflow-y: auto;
    h2 {
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 10px;
      color: $font;
    }
  }
 
  &#size-modal,
  &#premium-modal {
    background: white;

    .row {
      height: 100%;
      .modal-col {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    h2 {
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 10px;
      color: $font;
    }

    p {
      margin-bottom: 10px;
      padding-right: 50px;
      line-height: 1.7;
      font-weight: 500;
    }

    img {
      width: 100%;
      margin-bottom: 25px;
    }

    .size-buttons {
      button.btn.grey {
        margin: 0 0 0 15px !important;
      }
    }

    .size-buttons,
    .close-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      select {
        width: 32%;
        font-size: 12px;
        height: 34px;
      }

      button.btn {
        width: 32%;
        background: $primary-color;
        border-color: $primary-color;
        color: white;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 11px;
        border-radius: 3px;
        padding: 8px 20px;

        &.grey {
          width: 32%;
          background: #e6e6e6;
          border-color: #e6e6e6;
          color: $button-background;
          font-weight: 600;
          text-transform: uppercase;
          font-size: 11px;
          margin: 5px 0 0;

          &:hover {
            background: darken(#e6e6e6, 5%);
          }
        }
      }
    }
  }
}

#submit-info-form {
  width: 100%;
  padding: 30px 0 30px;
  background: $header-background;
  display: flex;
  justify-content: center;
  align-items: center;

  .col-5 {
    display: flex;
    align-items: center;
  }

  #form {
    width: 100%;
    display: flex;
    flex-direction: column;

    button {
      align-self: center;
    }

    .subrow {
      display: flex;
      justify-content: space-between;
    }

    h4 {
      font-size: 14px;
      font-weight: 700;
      margin: 0 0 10px;
    }

    hr {
      margin: 10px 0 15px;
    }

    input {
      margin-bottom: 15px;

      &.half {
        width: 49%;
      }
    }

    textarea {
      margin-bottom: 15px;
      font-size: 12px;
    }

    .error {
      margin: 15px 0;
      color: red;
      font-weight: bold;
    }

    input,
    text-area {
      &.error-field {
        border: 2px solid red;
      }
    }
  }
}

@media (max-width: 1200px) {
  .modal-content {
    width: 90%;
  }
}
