@import 'variables';

body {
  background: #f6f8fd;
}

button.btn {
  font-size: 12px;
  border-radius: 2px;
  padding: 5px 10px;
}

input.form-control {
  font-size: 12px;
}

.cardbox {
  background: white;
  border-radius: 3px;
  box-shadow: 0 0 3px #ddd;
  border: 0;
  padding: 15px;
  margin-bottom: 25px;
}
.cardbox-header {
  margin: 0 10px 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  color: $primary-color;

  h4 {
    font-weight: bold;
    font-size: 16px;
    display: inline;
    margin: 5px 0;
  }

  .close-link {
    margin-left: 10px;
    font-size: 12px;
    color: $primary-color;
    font-weight: 500;
  }

  .delete-motif {
    margin: 0 0 0 auto;
    color: red;
    font-size: 12px;
    font-weight: bold;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.admin-header {
  background: $primary-color;
  display: flex;
  align-items: center;
  padding: 15px 15px;
  margin-bottom: 20px;

  h3 {
    color: white;
    font-size: 16px;
    margin: 0;
  }

  a {
    font-size: 12px;
    color: white;
    text-decoration: none;
    margin-left: auto;
    display: inline;

    &:hover {
      cursor:pointer;
      text-decoration: underline;
    }
  }

  p.logout {
    display: inline;
    color: white;
    margin-left: 15px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

#admin-content {
  position: relative;
}
.admin-notification {
  position: fixed;
  top: 30px;
  right: 30px;
  padding: 30px 50px;
  background: white;
  border-radius: 3px;
  box-shadow: 0 0 5px #ccc;
  color: $primary-color;
  z-index: 100000;

  img {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 20px;
    opacity: 0.6;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}

#admin-overview {
  width: 100%;
  position: relative;
  padding: 30px 0 25px !important;

  p#analytics-export {
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: $primary-color;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  h4 {
    color: $primary-color;
    font-size: 16px;
    font-weight: 700;
  }

  p.stat {
    font-size: 36px;
    color: $primary-color;
    font-weight: 700;

    &.not-last {
      margin: 0 0 20px;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      margin-bottom: 5px;
      font-size: 12px;

      p {
        font-weight: 600;
        display: inline-block;
        min-width: 25px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

#modules-router {
  line-height: 1.95;
  padding: 0 15px;

  a {
    border-radius: 4px 4px 0 0;
    background: #ddd;
    color: #333;
    padding: 5px 15px;
    border: 1px solid #ddd;
    font-size: 12px;
    margin-right: 5px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      background-color: darken(#ccc, 5%);
      border-color: darken(#ccc, 5%);
    }

    &.active {
      background: $primary-color;
      border-color: $primary-color;
      color: white;
    }
  } 
}

#admin-modules {
  width: 100%;
  margin-bottom: 25px;
}

#new-motif,
#edit-motif {
  .flexbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .edit-motif-subcategories {
    padding-left: 10px;
    padding-bottom: 15px;

    div {
      display: inline;
    }

    p.motif-subcategory {
      display: inline;
      padding: 5px 20px;
      color: #495051;
      border: 1px solid #ced4da;
      border-radius: 3px 0 0 3px;
      border-right: 0;
    }

    p.delete-subcategory {
      display: inline;
      padding: 5px 8px;
      margin-right: 10px;
      background: #ced4da;
      border: 1px solid #ced4da;
      font-weight: bold;
      color: white;
      border-radius: 0 3px 3px 0;

      &:hover {
        cursor: pointer;
        background: darken(#ced4da, 10%);
        border-color: darken(#ced4da, 10%);
      }
    }
  }

  img {
    height: 32px;
    margin: 0 10px 10px;
  }

  label {
    display: block;
    margin-bottom: 10px;
    margin: 0 10px 2px;
    font-size: 8px;
    font-weight: 900;
  }

  input, textarea, select {
    display: inline;
    font-size: 12px;
    width: 200px;
    margin: 0 10px 15px 10px;
    font-weight: 500;

    &::placeholder {
      font-weight: normal;
      color: #ccc;
      opacity: 1;
    }


    &[name='name'],
    &[name='file'] {
      width: 200px;
    }

    &[name='SubcategoryId'],
    &[name='adminTags'],
    &[name='searchTags'],
    &[name='backgroundColors'] {
      width: 250px;
    }

    &[name='width'],
    &[name='height'],
    &[name='upcharge'],
    &[name='maxUses'],
    &[name='priority'] {
      width: 100px;
    }

    &[name='colorKey'] {
      width: 350px;
    }
  }

  hr {
    margin: 5px 10px 25px;
    border: 0;
    border-top: 1px solid #eee;
  }

  button.btn.btn-success {
    background: $primary-color;
    border-color: $primary-color;
    width: 100%;
    margin-left: 10px;
  }
}

p.delete-restriction {
  color: darkred;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

table.motif-list,
table.restriction-list,
table.submitted-belt-list {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;

  thead {      
    tr {
      th {
        font-size: 10px;
        padding: 5px 10px 7px;
        color: $primary-color;
        border-right: 1px solid #eee;

        &:last-child {
          border: 0;
        }
      }
    }
  }

  tbody {
    tr {
      border-top: 2px solid #eee;

      &:last-child {
        border-bottom: 2px solid #eee;
      }

      &:hover {
        td {
          background: #eee;
          cursor: pointer;
        }
      }

      td {
        background: #fcfcfc;
        padding: 3px 10px;
        border-right: 1px solid #eee;
        font-size: 10px;

        &:last-child {
          border: 0;
        }

        img {
          height: 25px;
        }

        svg {
          height: 40px;
        }

        p {
          font-size: 10px;
          margin: 0;
        }

        .motif-name {
          font-weight: bold;
        }

        &.restriction-name {
          padding: 8px 10px;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }
}

table.submitted-belt-list {
  tbody {
    tr {
      td.resend {
        transition: background 0.2s ease-in-out;
        &:hover {
          background: $primary-color;
          color: white;
          text-decoration: underline;
        }
      }
    }
  }
}

#admin-motifs {
  padding: 0;
  
  a.create-new {
    display: inline-block;
    font-size: 12px;
    margin-bottom: 10px;
    color: $primary-color;
    font-weight: bold;
  }

  input {
    font-size: 12px;
    margin-bottom: 20px;
  }

  p.results-count {
    margin: 5px 5px 5px 0;
    font-size: 10px;
    color: #ccc;
    float: right;
  }
}

p {
  margin: 0;
  font-size: 12px;
}

.selected {
  display: flex;
  div{
    
    width: 49%;
  }
  :nth-child(1){
    margin-right: 2%;
  }
}

#admin-restrictions {

  #new-restriction {
    select {
      width: 300px;
      display: inline-block;
      margin-right: 15px;
      font-size: 12px;
    }
    input {
      width: 200px;
      display: inline-block;
      margin-right: 15px;
      font-size: 12px;

      &.message {
        width: 400px;
      }
    }
    button {
      background-color: $primary-color;
      border-color: $primary-color;
    }

    .restriction-comparison {
      display: inline-block;
      margin-right: 15px;
      font-weight: bold;
    }
  }
}

#admin-categories {
  #new-category {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
  
    h4 {
      font-size: 12px;
      margin: 0 10px 0 0;
    }

    input {
      max-width: 300px;
      margin-right: 0;
      border-radius: 2px;
    }

    button.btn {
      margin-left: 10px;
      border-radius: 2px;
      padding: 6px 10px;
      background-color: $primary-color;
      border-color: $primary-color;
    }

    a {
      margin-left: 10px;
      font-size: 12px;
      color: $primary-color;
      text-decoration: underline;
    }
  }
}

.category-container {
  margin-bottom: 20px;
  box-shadow: 0 0 6px #ccc;
  border-radius: 3px 3px 0 0;

  &:last-child {
    margin-bottom: 0;
  }

  p.toggle-edit {
    font-weight: 500;
    padding-left: 20px;
    font-size: 10px;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .category {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #eee;
    padding: 5px 10px;
    font-weight: bold;
    border-radius: 3px 3px 0 0;

    #edit-category {
      display: flex;
      align-items: center;
      margin-left: 10px;

      input {
        font-size: 10px;
        border-radius: 2px 0 0 2px;
      }

      button.btn {
        margin: 0 25px 0 0;
        border-radius: 0 2px 2px 0;
        white-space: nowrap;
        padding: 6px 15px;
        font-size: 10px;
      }
    }
    
    p {
      margin: 0;

      &.delete {
        margin-right: 15px;
        margin-left: auto;
        margin-bottom: 1px;
        color: darkred;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    #drag-handle {
      width: 15px;
      height: 20px;
      fill: #333;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .subcategory-container {
    width: 100%;

    .subcategory {
      font-size: 10px;
      padding: 5px 10px;
      background: white;
      border-bottom: 1px solid #eee;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;

      #edit-subcategory {
        display: flex;
        align-items: center;

        input {
          font-size: 8px;
          border-radius: 2px 0 0 2px;
          margin-left: 15px;
          width: 200px;
        }

        button.btn {
          margin: 0 25px 0 0;
          border-radius: 0 2px 2px 0;
          white-space: nowrap;
          font-size: 8px;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      p {
        margin: 0;

        &.delete {
          margin-left: auto;
          font-size: 10px;
          color: darkred;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.delete-personmotif{
  margin-left: auto;
  font-size: 10px;
  color: darkred;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

#admin-upload {
  padding: 15px 15px 25px;
  h5 {
    font-weight: 700;
    font-size: 16px;
    color: $primary-color;
    text-transform: uppercase;
  }
  p {
    font-size: 12px;
    margin-bottom: 10px;
    width: 400px;
  }
  input {
    font-size: 12px;
  }
}

#admin-submitted-belts {
  .submitted-belt-list {
    tbody {
      tr {
        td {
          font-size: 12px;
          padding: 10px;
        }
      }
    }
  }
}

.admin-motif-info-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  div.background {
    background: rgba(200, 200, 200, .6);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  div {
    border-radius: 3px;
    box-shadow: 0 0 10px #ccc;
    z-index: 5;
    padding: 30px;
    width: 400px;
    border: 1px solid #ccc;
    background: repeating-linear-gradient(
      -55deg,
      #f6f6f6,
      #f6f6f6 4px,
      #eee 4px,
      #eee 8px
    );

    h5 {
      font-weight: 700;
      font-size: 18px;

      img {
        float: right;
        height: 66px;
        margin: 0 0 15px 15px;
      }
    }

    p {
      font-weight: 500;
      margin: 10px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


