@import 'variables';

.info {
  background: #f6f6f6;
  padding: 35px 0 25px;
  min-height: 100vw;

  hr {
    margin-bottom: 25px;
  }

  h1 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 35px;
    color: $font;
  }

  h2 {
    font-size: 19px;
    font-weight: 700;
    margin: 20px 0;
    text-decoration: underline;
  }

  h3 {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  p {
    font-size: 13px;
    line-height: 1.9;
    margin-bottom: 15px;
  }

  ul {
    li {
      font-size: 13px;
      line-height: 1.9;
      margin-bottom: 5px;
    }
  }

  .col-10 {
    div {
      margin-bottom: 30px;

      img {
        width: 100%;
      }
    }
  }

  table {
    font-size: 13px;

    tr {
      td:first-child {
        width: 150px;
        padding: 6px 0;
        font-weight: 600;
      }

      td:last-child {
        padding: 6px 0;
      }
    }
  }
}

.contentText {
  font-size: 13px;
}