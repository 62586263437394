@import 'variables';

#login-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-module {
    width: 200px;

    h4 {
      font-size: 16px;
      font-weight: bold;
      color: $primary-color;
      margin: 0 0 10px;
    }

    input {
      font-size: 12px;
      margin-bottom: 15px;
    }

    button.btn {
      width: 100%;
      font-size: 12px;
    }

    .error-message {
      color: red;
      margin-top: 10px;
    }

    a {
      font-size: 10px;
    }
  }
}