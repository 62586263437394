@import 'variables';

#submit-complete {
  padding: 30px 0 50px;
  background: $header-background;
  border-top: 2px solid $hr;
  display: flex;
  justify-content: center;
  align-items: center;

  #complete-container {
    display: flex;
    justify-content: center;
    align-items: center;

    #complete {
      max-width: 550px;
      width: 100%;
      text-align: center;
      height: 100%;

      .pay-modal {
        width: 450px;
        margin: 0 auto;
        padding: 30px;
        border: 1px solid #ddd;
        border-radius: 10px;
        margin-top: 40px;
      }

      h2 {
        font-size: 26px;
        font-weight: 700;
        color: $primary-color;
        margin-bottom: 0px;
      }

      p {
        color: $primary-color;
        margin: 25px 0 30px;
        font-size: 14px;
        line-height: 1.8;
        font-weight: 500;

        &.sub {
          margin: 0 0 25px;
          font-size: 12px;
        }

        &.head {
          margin: 0 0 15px;
        }
      }

      hr {
        margin-top: 40px;
      }

      p.complete-info {
        font-family: Courier New;
        // text-transform: uppercase;
        margin: 5px 0;
        font-size: 16px;

        span {
          font-family: 'Open Sans', sans-serif;
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 700;
          margin-right: 10px;
        }
      }
    }
  }
}

.confirm-link {
  margin: 5px 0;
  .confirm-text {
    font-family: 'Open Sans', sans-serif;
    color: $primary-color;
    font-size: 14px;
    margin: 25px 0 0;
  }
  a {
    color: '#007bff';
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }   
  }
}

