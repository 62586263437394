$primary-color: #1e2e5a;
$primary-grey: #495473;

$header-background: #f6f6f6;
$font: #050740;
$button-background: #303352;
$hr: #e6e6e6;

.m0 {
  margin: 0;
}

button.btn,
a.btn {
  background: $button-background;
  border: 0;
  color: white;
  font-size: 11px;
  border-radius: 3px;
  padding: 8px 20px;
  margin-left: 15px;
  font-weight: 600;
  text-transform: uppercase;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out, border 0.2s ease-in-out;

  &:focus {
    outline: none;
  }

  &:hover {
    background: darken($primary-color, 5%);
    color: white;
  }

  &.right {
    float: right;
    padding: 5px 17px;
  }

  &.reset-button {
    background: transparent;
    color: red;
    border: 1px solid red;
    border-radius: 3px;
    height: 32px;
    margin: 0 10px 0 0;
    font-size: 10px;
    line-height: 1;

    &.clear {
      border: 1px solid $primary-color;
      color: $primary-color;

      &:hover {
        color: darken($primary-color, 5%);
        border-color: darken($primary-color, 5%);
      }
    }

    &:hover {
      background: transparent;
      border-color: darken(red, 10%);
      color: darken(red, 10%);
    }
  }

  &.grey {
    background: #e6e6e6;
    color: $button-background;

    &:hover {
      background: darken($primary-color, 5%);
      color: white;
    }
  }

  &.ghost {
    background: #f6f6f6;
    color: $primary-color;
    border: 1px solid $primary-color;

    &.white {
      background: transparent;
    }

    &:hover {
      background: darken($primary-color, 5%);
      color: white;
    }
  }

  &.btn-disabled {
    background: #e6e6e6;
    color: $button-background;
    position: relative;

    .button-tooltip {
      display: none;
      background: #f6f6f6;
      border: 1px solid #ccc;
      color: #222;
      border-radius: 3px;
      position: absolute;
      top: 45px;
      left: 0;
      padding: 10px;
      line-height: 1.6;
      font-size: 11px;
      text-transform: none;
      z-index: 1000;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:hover {
      cursor: not-allowed;
      color: #ccc;

      .button-tooltip {
        display: block;
      }
    }
  }

  &.btn-share {
    background: $button-background;
    border: 0;
    color: white;
    font-size: 11px;
    border-radius: 3px;
    font-weight: 600;
    text-transform: uppercase;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
    width: 100%;
    margin: 5px 0 0;
    font-size: 10px;
    padding: 5px;
    text-align: center;
  }

  &.btn-m0 {
    margin: 0;
  }
}
